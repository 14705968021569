import { useEffect, useState } from "react";
import "./App.css";
import faceIO from "@faceio/fiojs";
const faceioInstance = new faceIO("fioa715c");
var coba = "";
function App() {
  const [faceio, setFaceio] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const initializeFaceIO = async () => {
      try {
        // Create a new instance of FaceIO with your public ID

        // Update state with the instance
        setFaceio(faceioInstance);
      } catch (error) {
        // Set error state if initialization fails
        setError("Failed to initialize FaceIO: " + error.message);
      }
    };
    initializeFaceIO();

    alert("tes->", coba);
  }, []);
  async function enrollNewUser() {
    faceio
      .enroll({
        locale: "auto", // Default user locale
        payload: {
          /* The payload we want to associate with this user
           * which is forwarded back to us upon future
           * authentication of this particular user.*/
          whoami: 123456, // Example of dummy ID linked to this particular user
          email: "john.doe@example.com",
        },
      })
      .then((userInfo) => {
        // User Successfully Enrolled!
        alert(
          `User Successfully Enrolled! Details:
                    Unique Facial ID: ${userInfo.facialId}
                    Enrollment Date: ${userInfo.timestamp}
                    Gender: ${userInfo.details.gender}
                    Age Approximation: ${userInfo.details.age}`
        );
        console.log(userInfo);
        // handle success, save the facial ID, redirect to dashboard...
      })
      .catch((errCode) => {
        console.info(errCode);
        // handle enrollment failure. Visit:
        // https://faceio.net/integration-guide#error-codes
        // for the list of all possible error codes
      });
    // try {
    //   // Call the enroll method of the FaceIO instance with necessary options
    //   const response = await faceio.enroll({
    //     locale: "auto",
    //     payload: {
    //       email: "example@gmail.com",
    //       pin: "12345",
    //     },
    //   });
    //   // Log enrollment details to the console
    //   console.log(
    //     `Unique Facial ID: ${response.facialId} Enrollment Date: ${response.timestamp} Gender: ${response.details.gender} Age Approximation: ${response.details.age}`
    //   );
    // } catch (error) {
    //   // Set error state if enrollment fails
    //   setError("Enrollment failed: " + error.message);
    // }
  }
  async function authenticateUser() {
    faceioInstance.restartSession();
    faceioInstance
      .authenticate({
        locale: "auto", // Default user locale
      })
      .then((userData) => {
        console.log("Success, user identified");
        // Grab the facial ID linked to this particular user which will be same
        // for each of his successful future authentication. FACEIO recommend
        // that your rely on this Facial ID if you plan to uniquely identify
        // all enrolled users on your backend for example.
        console.log("Linked facial Id: " + userData.facialId);
        // Grab the arbitrary data you have already linked (if any) to this particular user
        // during his enrollment via the payload parameter of the enroll() method.
        console.log("Payload: " + JSON.stringify(userData.payload));
        // {"whoami": 123456, "email": "john.doe@example.com"} set via enroll()
      })
      .catch((errCode) => {
        console.info(errCode);
        // handle authentication failure. Visit:
        // https://faceio.net/integration-guide#error-codes
        // for the list of all possible error codes
      });
    // try {
    //   // Call the authenticate method of the FaceIO instance with necessary options
    //   const userData = await faceioInstance.authenticate({
    //     locale: "auto", // Default user locale
    //   });
    //   // const response = await faceio.authenticate({
    //   //   locale: "auto",
    //   // });
    //   // Log authentication details to the console
    //   // console.log(
    //   //   `Unique Facial ID: ${response.facialId} PayLoad: ${response.payload}`
    //   // );
    // } catch (error) {
    //   // Set error state if authentication fails
    //   setError("Authentication failed: " + error.message);
    // }
  }
  return (
    <div className="App">
      <button onClick={enrollNewUser}>Enroll New User</button>
      <button onClick={authenticateUser}>Authenticate User</button>
    </div>
  );
}

export default App;
